import Rails from "@rails/ujs";
Rails.start();
require("@rails/activestorage").start()
import "channels"
import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = false
import $ from "jquery";

// Main Stylsheet
import "../stylesheets/application.scss";

// Custom slider for "Our Services Section"
import "../javascript/our-service-slider"

// Custom slider for "Our Technologies Section"
import "../javascript/our-technologies-slider"

// Navbar & Burgermenu
import "../javascript/navbar"

// Swipers
import "../javascript/swiper"

import "tw-elements/dist/css/tw-elements.min.css";

// Tw-Elements
import { Datepicker, Input, Collapse, initTE } from "tw-elements";
initTE({ Datepicker, Input, Collapse }, { allowReinits: true });
