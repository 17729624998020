const yellow = "#F1BE17";
const blue = "#1E446B";
const white = "#FFFFFF";

document.addEventListener('DOMContentLoaded', function() {
    let navBarPresent = document.querySelector('nav')

    if(navBarPresent) {
        // Javascript for Navbar Burger Menu
        var cls1fill = "#1e446b";
        var cls2fill = "#b0d8f4";
        var cls3fill = "#b0d8f4";

        // gets elements as global variables
        var logo = document.getElementById("sophisticates-logo")
        var cls_1_list = logo.getElementsByClassName("so-logo-cls-1");
        var cls_2_list = logo.getElementsByClassName("so-logo-cls-2");
        var cls_3_list = logo.getElementsByClassName("so-logo-cls-3");

        // Init
        var menuVisible = false;
        setTransitionDurationOfBurgerMenu("0.5s")
        switchDisplayOfBurgerMenu()

        document.getElementById("burger").addEventListener("click", function () {
            switchDisplayOfBurgerMenu();
        });

        function setTransitionDurationOfBurgerMenu(duration) {
            document.getElementById("burger-top-line").style.transitionDuration = duration
            document.getElementById("burger-bottom-line").style.transitionDuration = duration
            document.getElementById("burger-menu").style.transitionDuration = duration
            for (let item of cls_1_list) {
                item.style.transitionDuration = duration;
            }
            for (let item of cls_2_list) {
                item.style.transitionDuration = duration;
            }
            for (let item of cls_3_list) {
                item.style.transitionDuration = duration;
            }
        }

        function switchDisplayOfBurgerMenu() {
            if (menuVisible) {
                document.getElementById("burger-top-line").style.backgroundColor = white;
                document.getElementById("burger-bottom-line").style.backgroundColor = white;
                document.getElementById("burger-bottom-line").style.opacity = 0;
                document.getElementById("burger-bottom-line").style.scale = 0, 0;
                document.getElementById("burger-menu").style.display = "block";
                for (let item of cls_1_list) {
                    item.style.fill = white;
                }
                for (let item of cls_2_list) {
                    item.style.fill = white;
                }
                for (let item of cls_3_list) {
                    item.style.fill = white;
                }
            } else {
                document.getElementById("burger-top-line").style.backgroundColor = blue;
                document.getElementById("burger-bottom-line").style.backgroundColor = blue;
                document.getElementById("burger-bottom-line").style.opacity = 100;
                document.getElementById("burger-bottom-line").style.scale = 1, 1;
                document.getElementById("burger-menu").style.display = "none";
                for (let item of cls_1_list) {
                    item.setAttribute('style', 'fill: cls1fill')
                }
                for (let item of cls_2_list) {
                    item.setAttribute('style', 'fill: cls2fill')
                }
                for (let item of cls_3_list) {
                    item.setAttribute('style', 'fill: cls3fill')
                }
            }
            menuVisible = !menuVisible;
        }
    }
});

// Get a reference to the navbar element
const navbar = document.querySelector('.so-navbar');

// Add a scroll event listener
window.addEventListener('scroll', function () {
    // Calculate the opacity based on the scroll position
    const opacity = Math.min(1, window.scrollY / 200); // Limit the opacity to 1

    // Update the background color with the new opacity
    navbar.style.backgroundColor = `rgba(255, 255, 255, ${opacity.toFixed(2)})`; // Limit decimal places
    navbar.style.borderBottomColor = `rgba(236, 240, 241, ${opacity.toFixed(2)})`; // Limit decimal places
});
