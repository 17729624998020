const yellow = "#F1BE17";
const blue = "#1E446B";
const white = "#FFFFFF";

document.addEventListener('DOMContentLoaded', function() {
  const soServices = document.querySelector("#so-services-details");

  if(!(soServices===null)) {
    // Sets current Element to "consulting" which is the first slider to be active
    var currentElement = "consulting";
    var currentIcon = document.getElementById("service-card-consulting-icon");
    var currentHeadline = document.getElementById("service-card-consulting-headline");
    appendCurrentElement(currentElement, currentIcon, currentHeadline);

    // Enables appending of all selectable headings
    document.getElementById("consulting_span").addEventListener("click", function () {
      appendCurrentElement("consulting", document.getElementById("service-card-consulting-icon"), document.getElementById("service-card-consulting-headline"));
    });
    document.getElementById("conception_span").addEventListener("click", function () {
      appendCurrentElement("conception", document.getElementById("service-card-conception-icon"), document.getElementById("service-card-conception-headline"));
    });
    document.getElementById("implementation_span").addEventListener("click", function () {
      appendCurrentElement("implementation", document.getElementById("service-card-implementation-icon"), document.getElementById("service-card-implementation-headline"));
    });
    document.getElementById("hosting_span").addEventListener("click", function () {
      appendCurrentElement("hosting", document.getElementById("service-card-hosting-icon"), document.getElementById("service-card-hosting-headline"));
    });

    // Adds Eventlistener to all consulting_cards which can change the slider content to consulting
    var consulting_cards = document.getElementsByClassName("consulting_card");
    for (let card of consulting_cards) {
      card.addEventListener("click", function () {
        appendCurrentElement("consulting", document.getElementById("service-card-consulting-icon"), document.getElementById("service-card-consulting-headline"));
      });
    }
    // Adds Eventlistener to all conception_cards which can change the slider content to conception
    var conception_cards = document.getElementsByClassName("conception_card");
    for (let card of conception_cards) {
      card.addEventListener("click", function () {
        appendCurrentElement("conception", document.getElementById("service-card-conception-icon"), document.getElementById("service-card-conception-headline"));
      });
    }
    // Adds Eventlistener to all idea_cards which can change the slider content to idea
//  var idea_cards = document.getElementsByClassName("idea_card");
//  for (let card of idea_cards) {
//      window.location.hash = "so-services-details";
//      appendCurrentElement("idea", document.getElementById("service-card-idea-icon"), document.getElementById("service-card-idea-headline"));
//    });
//  }

    // Adds Eventlistener to all implementation_cards which can change the slider content to implementation
    var implementation_cards = document.getElementsByClassName("implementation_card");
    for (let card of implementation_cards) {
      card.addEventListener("click", function () {
        appendCurrentElement("implementation", document.getElementById("service-card-implementation-icon"), document.getElementById("service-card-implementation-headline"));
      });
    }

    // Appends the Element and changes innerHtml-Text, Icon and changes colour of selected Heading in the Slider
    function appendCurrentElement(itemToBeAppended, iconToBeAppended, headlineToBeAppended) {
      removeCurrentElement(currentElement);
      currentElement = itemToBeAppended;
      document.getElementById("icon-placeholder").innerHTML = iconToBeAppended.innerHTML;
      document.getElementById("headline-placeholder").innerHTML = headlineToBeAppended.innerHTML;
      switch (itemToBeAppended) {
        case "consulting":
          document.getElementById("consulting_line").style.backgroundColor = yellow;
          document.getElementById("consulting_span").style.color = yellow;
          document.getElementById("bulletpoint_01").innerText = "Frische Impulse und Experten-Input für die Klärung Ihrer Herausforderungen";
          document.getElementById("bulletpoint_02").innerText = "Blick von Außen auf Ihre Prozesse und IT-Infrastruktur";
          document.getElementById("bulletpoint_03").innerText = "Kurzfristige Beratung auf Abruf oder regelmäßig planbares Sparring";
          document.getElementById("bulletpoint_04").innerText = "Evaluation konkreter Ideen oder gemeinsames Brainstorming";
          document.getElementById("result").innerText = "Digitale Kompetenz für Ihre Herausforderung.";
          break;
//      case "idea":
//        document.getElementById("idea_development_line").style.backgroundColor = yellow;
//        document.getElementById("idea_development_span").style.color = yellow;
//        document.getElementById("bulletpoint_01").innerText = "Eingangsrecherche von Branche, Geschäftsmodell und Herausforderung Ihres Vorhabens";
//        document.getElementById("bulletpoint_02").innerText = "Kunden-Workshop zur Erarbeitung und Entwicklung von Ideen und Lösungsansätzen";
//        document.getElementById("bulletpoint_03").innerText = "Bewertung der Umsetzbarkeit und Entwicklung einer Ideenskizze (Pre-Konzept)";
//        document.getElementById("bulletpoint_04").innerText = "Präsentation der Ideenskizze inkl. Ausblick für die Konzeptentwicklung und Programmierung";
//        document.getElementById("result").innerText = "Executive Summary als Ideenpapier oder Präsentation";
//        break;
        case "conception":
          document.getElementById("service-card-conception-icon").style.fill = yellow;
          document.getElementById("conception_line").style.backgroundColor = yellow;
          document.getElementById("conception_span").style.color = yellow;
          document.getElementById("bulletpoint_01").innerText = "Umfangreiche Erarbeitung der Details Ihrer Herausforderung inkl. Experten-Beratung zu Ihrem Bedarf";
          document.getElementById("bulletpoint_02").innerText = "Umsetzungskonzept inkl. Beschreibung und ggf. Visualisierung jedes Features sowie Beschreibung der Abhängigkeiten von Features und Ausbaustufen in einer Übersicht";
          document.getElementById("bulletpoint_03").innerText = "Workshop zur Abstimmung der ökonomisch sinnvollen Optimallösung";
          document.getElementById("bulletpoint_04").innerText = "Ableitung von Abfolge, Dauer und Budgets für die Produktumsetzung";
          document.getElementById("result").innerText = "Zeit-, Kosten- und Maßnahmenplan für die Umsetzung Ihres Projektes";
          break;
        case "implementation":
          document.getElementById("implementation_line").style.backgroundColor = yellow;
          document.getElementById("implementation_span").style.color = yellow;
          document.getElementById("bulletpoint_01").innerText = "Umsetzung (Design & Programmierung) des digitalen Produkts anhand der geplanten Konzeptbestandteile";
          document.getElementById("bulletpoint_02").innerText = "Ableitung von Umsetzungsdauer und Liefertermin aus dem Konzept";
          document.getElementById("bulletpoint_03").innerText = "Regelmäßige Dokumentation des aktuellen Stands der Umsetzung (Softwareversion, Featurebeschreibungen) und Bereitstellung per Review-App";
          document.getElementById("bulletpoint_04").innerText = "Anpassungen des Konzepts oder einzelner Features agil möglich; Änderungen beeinflussen Zeitplanung und / oder Umfang der Umsetzung";
          document.getElementById("result").innerText = "Ihr Projekt als programmierte Anwendung";
          break;
        case "hosting":
          document.getElementById("hosting_line").style.backgroundColor = yellow;
          document.getElementById("hosting_span").style.color = yellow;
          document.getElementById("bulletpoint_01").innerText = "Automatisch skalierbarer und redundanter Betrieb Ihrer Anwendungen in Public Clouds oder On-Premises (Amazon Web Services, Microsoft Azure, DigitalOcean)";
          document.getElementById("bulletpoint_02").innerText = "24/7-Monitoring für automatisches Alerting und schnelle Reaktion bei Schwierigkeiten";
          document.getElementById("bulletpoint_03").innerText = "Fortwährende Pflege und Updates zur Erhaltung höchster Sicherheitsstandards";
          document.getElementById("bulletpoint_04").innerText = "Moderne professionelle Konfiguration für unterbrechungsfreien Betrieb, auch während des Einspielens von Updates";
          document.getElementById("result").innerText = "Zuverlässiger Betrieb Ihrer Softwarelösung in public oder private Cloud";
          break;
      }
    }

    // "Removes" the current Element
    function removeCurrentElement(currentElement) {
      switch (currentElement) {
        case "consulting":
          soServices.querySelector("#consulting_line").style.backgroundColor = blue;
          soServices.querySelector("#consulting_span").style.color = white;
        case "conception":
          soServices.querySelector("#conception_line").style.backgroundColor = blue;
          soServices.querySelector("#conception_span").style.color = white;
        case "implementation":
          soServices.querySelector("#implementation_line").style.backgroundColor = blue;
          soServices.querySelector("#implementation_span").style.color = white;
        case "hosting":
          soServices.querySelector("#hosting_line").style.backgroundColor = blue;
          soServices.querySelector("#hosting_span").style.color = white;
      }
    }
  }
});
