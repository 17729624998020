// core version + navigation, pagination & autoplay modules:
import Swiper from 'swiper';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';

// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

document.addEventListener('DOMContentLoaded', function() {

    // Swiper index /#trusted-by
    Swiper.use([Autoplay, Navigation, Pagination]);

    const swiper01 = new Swiper('.swiper01', {
        // Optional parameters
        direction: 'horizontal',
        autoHeight: true,
        loop: true,
        slidesPerView: 1,
        spaceBetween: 50,

        autoplay: {
            delay: 2000,
        },

        // Navigation arrows
        navigation: {
            nextEl: '.swiper01-next-button',
            prevEl: '.swiper01-prev-button',
        },

        breakpoints: {
            // when window width is >= 320px
            0: {
                slidesPerView: 1,
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 3,
            },
            // when window width is >= 1024px
            1024: {
                slidesPerView: 4,
            }
        }
    });

    // Swiper webportal /#web-portals-from-customers
    const swiper03 = new Swiper('.swiper03', {
        // Optional parameters
        direction: 'horizontal',
        loop: true,
        slidesPerView: 1,
        spaceBetween: 15,

        autoplay: {
            delay: 8000,
        },

        // Navigation arrows
        navigation: {
            nextEl: '.swiper03-next-button',
            prevEl: '.swiper03-prev-button',
        },

        breakpoints: {
            // when window width is >= 0px
            0: {
                slidesPerView: 1,
                spaceBetween: 15
            },
            // when window width is >= 480px
            640: {
                slidesPerView: 2,
                spaceBetween: 15
            },
            // when window width is >= 1024px
            1024: {
                slidesPerView: 3,
                spaceBetween: 15
            }
        }
    });
});
