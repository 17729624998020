const yellow = "#F1BE17";
const blue = "#1E446B";
const white = "#FFFFFF";

document.addEventListener('DOMContentLoaded', function() {
  const soServices = document.querySelector("#so-technologies");

  if(!(soServices===null)) {
    // Sets current Element to "hosting_and_technologies" which is the first slider to be active
    var currentElement = "concept_and_development";
    var currentIcon = document.getElementById("service-card-concept_and_development-icon");
    var currentHeadline = document.getElementById("service-card-concept_and_development-headline");
    appendCurrentElement(currentElement, currentIcon, currentHeadline);

    // Enables appending of all selectable headings
    document.getElementById("hosting_and_technologies_span").addEventListener("click", function () {
      appendCurrentElement("hosting_and_technologies", document.getElementById("service-card-hosting_and_technologies-icon"), document.getElementById("service-card-hosting_and_technologies-headline"));
    });
    document.getElementById("concept_and_development_span").addEventListener("click", function () {
      appendCurrentElement("concept_and_development", document.getElementById("service-card-concept_and_development-icon"), document.getElementById("service-card-concept_and_development-headline"));
    });
    document.getElementById("automation_and_integration_span").addEventListener("click", function () {
      appendCurrentElement("automation_and_integration", document.getElementById("service-card-automation_and_integration-icon"), document.getElementById("service-card-automation_and_integration-headline"));
    });
    document.getElementById("monitoring_and_security_span").addEventListener("click", function () {
      appendCurrentElement("monitoring_and_security", document.getElementById("service-card-monitoring_and_security-icon"), document.getElementById("service-card-monitoring_and_security-headline"));
    });

    // Adds Eventlistener to all hosting_and_technologies_cards which can change the slider content to hosting_and_technologies
    var hosting_and_technologies_cards = document.getElementsByClassName("hosting_and_technologies_card");
    for (let card of hosting_and_technologies_cards) {
      card.addEventListener("click", function () {
        appendCurrentElement("hosting_and_technologies", document.getElementById("service-card-hosting_and_technologies-icon"), document.getElementById("service-card-hosting_and_technologies-headline"));
      });
    }
    // Adds Eventlistener to all concept_and_development_cards which can change the slider content to concept_and_development
    var concept_and_development_cards = document.getElementsByClassName("concept_and_development_card");
    for (let card of concept_and_development_cards) {
      card.addEventListener("click", function () {
        appendCurrentElement("concept_and_development", document.getElementById("service-card-concept_and_development-icon"), document.getElementById("service-card-concept_and_development-headline"));
      });
    }
    // Adds Eventlistener to all idea_cards which can change the slider content to idea
//  var idea_cards = document.getElementsByClassName("idea_card");
//  for (let card of idea_cards) {
//      window.location.hash = "so-services-details";
//      appendCurrentElement("idea", document.getElementById("service-card-idea-icon"), document.getElementById("service-card-idea-headline"));
//    });
//  }

    // Adds Eventlistener to all automation_and_integration_cards which can change the slider content to automation_and_integration
    var automation_and_integration_cards = document.getElementsByClassName("automation_and_integration_card");
    for (let card of automation_and_integration_cards) {
      card.addEventListener("click", function () {
        appendCurrentElement("automation_and_integration", document.getElementById("service-card-automation_and_integration-icon"), document.getElementById("service-card-automation_and_integration-headline"));
      });
    }

    // Adds Eventlistener to all automation_and_integration_cards which can change the slider content to automation_and_integration
    var monitoring_and_security_cards = document.getElementsByClassName("monitoring_and_security_card");
    for (let card of monitoring_and_security_cards) {
      card.addEventListener("click", function () {
        appendCurrentElement("monitoring_and_security", document.getElementById("service-card-monitoring_and_security-icon"), document.getElementById("service-card-monitoring_and_security-headline"));
      });
    }

    // Appends the Element and changes innerHtml-Text, Icon and changes colour of selected Heading in the Slider
    function appendCurrentElement(itemToBeAppended, iconToBeAppended, headlineToBeAppended) {
      removeCurrentElement(currentElement);
      currentElement = itemToBeAppended;
      document.getElementById("icon-placeholder").innerHTML = iconToBeAppended.innerHTML;
      document.getElementById("headline-placeholder").innerHTML = headlineToBeAppended.innerHTML;
      switch (itemToBeAppended) {
        case "hosting_and_technologies":
          displayElements()
          document.getElementById("hosting_and_technologies_line").style.backgroundColor = yellow;
          document.getElementById("hosting_and_technologies_span").style.color = yellow;
          document.getElementById("bulletpoint_01").innerText = "Wir stellen sicher, dass Ihre App sowohl auf Desktop- als auch auf mobilen Geräten eine optimale Nutzererfahrung bietet.";
          document.getElementById("bulletpoint_02").innerText = "Unsere Erfahrung in der Verwaltung von Cloud-Infrastrukturen auf AWS und Digital Ocean ermöglicht es uns, skalierbare und zuverlässige Lösungen für Sie bereitzustellen.";
          document.getElementById("bulletpoint_03").innerText = "Mit Kubernetes als Hosting-Plattform können wir die Skalierbarkeit Ihrer App in Echtzeit steuern, Ressourcen effizient verwalten und gleichzeitig unterbrechungsfreies Hosting gewährleisten.";
          document.getElementById("bulletpoint_04").innerText = "Mit kontinuierlichen Updates stellen wir sicher, dass Ihre App auf dem neuesten Stand der Technik bleibt und etwaige Sicherheitslücken geschlossen werden.";
          hideElements([5,6,7])
          document.getElementById("result").innerText = "Zuverlässiger Betrieb Ihrer Softwarelösung in public oder private Cloud.";
          break;
        case "concept_and_development":
          displayElements()
          document.getElementById("service-card-concept_and_development-icon").style.fill = yellow;
          document.getElementById("concept_and_development_line").style.backgroundColor = yellow;
          document.getElementById("concept_and_development_span").style.color = yellow;
          document.getElementById("bulletpoint_01").innerText = "Gemeinsam mit Ihnen werden wir das Grobkonzept diskutieren und die Prioritäten für die einzelnen Funktionen Ihrer App festlegen.";
          document.getElementById("bulletpoint_02").innerText = "Wir erstellen für Sie Prototypen und Mockups, um Design und Funktionen Ihrer App zu visualisieren.";
          document.getElementById("bulletpoint_03").innerText = "Wir setzen auf agile Methoden, um die Anforderungen für Ihre App kontinuierlich anzupassen und zu erweitern.";
          document.getElementById("bulletpoint_04").innerText = "Unser Entwicklungsframework bietet die Möglichkeit, eine App einfach zu erweitern und neue Features hinzuzufügen.";
          hideElements([5,6,7])
          document.getElementById("result").innerText = "Digitale Kompetenz für Ihre individuellen Ansprüche.";
          break;
        case "automation_and_integration":
          displayElements()
          document.getElementById("automation_and_integration_line").style.backgroundColor = yellow;
          document.getElementById("automation_and_integration_span").style.color = yellow;
          document.getElementById("bulletpoint_01").innerText = "Wir automatisieren wiederkehrende Aufgaben für Sie, wodurch sich in Ihrem Betrieb kostbare Zeit einsparen lässt und menschliche Fehler reduzieren lassen.";
          document.getElementById("bulletpoint_02").innerText = "Durch einen automatisierten E-Mail-Versands können Sie wichtige Benachrichtigungen zeitnah an die Nutzer Ihrer App senden.";
          document.getElementById("bulletpoint_03").innerText = "Durch maßgeschneiderte API-Entwicklung können wir externe Softwaresysteme in Ihre Infrastruktur integrieren und so die Effizienz Ihrer Prozesse steigern.";
          document.getElementById("bulletpoint_04").innerText = "Wir entwickeln für Sie Lösungen, mit denen Sie Daten aus externen Softwaresystemen mühelos importieren, exportieren und synchronisieren können.";
          hideElements([5,6,7])
          document.getElementById("result").innerText = "Zeit-, Kosten- und Aufwandseinparungen durch Automatisierung.";
          break;
        case "monitoring_and_security":
          displayElements()
          document.getElementById("monitoring_and_security_line").style.backgroundColor = yellow;
          document.getElementById("monitoring_and_security_span").style.color = yellow;
          document.getElementById("bulletpoint_01").innerText = "Mit individuellen Berechtigungen und Benutzerrollen können wir die Zugriffssteuerung in Ihrer App präzise für Sie konfigurieren.";
          document.getElementById("bulletpoint_02").innerText = "Wir bieten umfassende Optionen für die Benutzerauthentifizierung in Ihrer App.";
          document.getElementById("bulletpoint_03").innerText = "Wir kümmern uns um die kontinuierliche Fehlerüberwachung, damit Ihre App stets fehlerfrei funktioniert.";
          document.getElementById("bulletpoint_04").innerText = "Wir benutzen robuste Testsuiten, um Entwicklungsfehler frühzeitig zu identifizieren und zu beheben.";
          document.getElementById("bulletpoint_05").innerText = "Die in Ihrer App generierten Daten visualisieren wir in aussagekräftigen Diagrammen, Statistiken und Berichten, um sie den Nutzern der App bereitstellen zu können.";
          document.getElementById("bulletpoint_06").innerText = "Unsere skalierbaren Multitenancy-Lösungen sind perfekt für die Implementierung einer Mehrmandantenfähigkeit in Ihrer App geeignet.";
          hideElements([7])
          document.getElementById("result").innerText = "Sicherheit und Zuverlässigkeit für Ihre App.";
          break;
      }
    }

    // "Removes" the current Element
    function removeCurrentElement(currentElement) {
      switch (currentElement) {
        case "hosting_and_technologies":
          soServices.querySelector("#hosting_and_technologies_line").style.backgroundColor = blue;
          soServices.querySelector("#hosting_and_technologies_span").style.color = white;
        case "concept_and_development":
          soServices.querySelector("#concept_and_development_line").style.backgroundColor = blue;
          soServices.querySelector("#concept_and_development_span").style.color = white;
        case "automation_and_integration":
          soServices.querySelector("#automation_and_integration_line").style.backgroundColor = blue;
          soServices.querySelector("#automation_and_integration_span").style.color = white;
        case "monitoring_and_security":
          soServices.querySelector("#monitoring_and_security_line").style.backgroundColor = blue;
          soServices.querySelector("#monitoring_and_security_span").style.color = white;
      }
    }
  }
});

function displayElements() {
  document.getElementById("bullet_01").style.display = 'block'
  document.getElementById("bullet_02").style.display = 'block'
  document.getElementById("bullet_03").style.display = 'block'
  document.getElementById("bullet_04").style.display = 'block'
  document.getElementById("bullet_05").style.display = 'block'
  document.getElementById("bullet_06").style.display = 'block'
  document.getElementById("bullet_07").style.display = 'block'
  document.getElementById("bullet_01-div").style.display = 'flex'
  document.getElementById("bullet_02-div").style.display = 'flex'
  document.getElementById("bullet_03-div").style.display = 'flex'
  document.getElementById("bullet_04-div").style.display = 'flex'
  document.getElementById("bullet_05-div").style.display = 'flex'
  document.getElementById("bullet_06-div").style.display = 'flex'
  document.getElementById("bullet_07-div").style.display = 'flex'
}

function hideElements(ids) {
  ids.forEach(id => {
    document.getElementById(`bullet_0${id}`).style.display = 'none'
    document.getElementById(`bullet_0${id}-div`).style.display = 'none'
    document.getElementById(`bulletpoint_0${id}`).innerText = "";
  });
}
